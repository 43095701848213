<template>
    <div
        class="task-sign-box"
        v-loading="loading"
    >
        <top-nav></top-nav>
        <header-guide @winscroll="winScroll"></header-guide>
        <bread-crumb></bread-crumb>

        <!-- ===============================头部项目和雇主信息=================================  -->
        <div class="t-top">
            <div class="left">
                <div class="name">{{task.task_name}}</div>
                <div class="time-box">
                    <div
                        class="type"
                        v-if="task.task_type == 1"
                    >竞价项目</div>
                    <div
                        class="type bx"
                        v-if="task.task_type == 2"
                    >比选项目</div>
                    <div
                        class="type rx"
                        v-if="task.task_type == 3"
                    >日薪项目</div>
                    <div
                        class="time-text"
                        v-if="task.status == 1"
                    >距离报名结束还剩</div>
                    <div
                        class="time"
                        v-if="task.status == 3"
                    >
                        <counter
                            :end-time="task.end_time"
                            @endCount="endCount"
                        ></counter>
                    </div>
                </div>
                <div class="prop-item">
                    <span class="prop-item-name">雇主：</span>
                    <span class="username">{{task.employer_name}}</span>
                    <span>于 {{task.approve_time}} 发布该项目</span>
                </div>

                <div class="prop-item">
                    <span class="prop-item-name">项目编号：</span>
                    <span>{{task.sn}}</span>
                </div>

                <div class="prop-item">
                    <span class="prop-item-name">服务分类：</span>
                    <span>{{task.speciality_type_value}}</span>
                </div>

                <div class="price">
                    <span class="price-name">项目酬金</span>
                    <span class="price-value">
                        <i v-if="task.task_type != 3">{{task.remuneration}}元</i>
                        <i v-if="task.task_type == 3">总酬金：{{task.remuneration}}元 &nbsp;&nbsp;工期：{{task.task_day}}天 &nbsp;&nbsp;每天酬金：{{task.remuneration/task.task_day}}元</i>
                    </span>
                </div>

                <div style="position:absolute;right:40px;top:34px;">
                    <span
                        style="font-size:20px;color:#587ddc"
                        v-if="task.status == 3"
                    >报名中</span>
                    <span
                        style="font-size:20px;color:#587ddc"
                        v-if="task.status == 4"
                    >评选中</span>
                    <span
                        style="font-size:20px;color:#587ddc"
                        v-if="task.status == 5"
                    >待签署</span>
                    <span
                        style="font-size:20px;color:#587ddc"
                        v-if="task.status == 6"
                    >待缴纳</span>
                    <span
                        style="font-size:20px;color:#587ddc"
                        v-if="task.status == 7"
                    >工作中</span>
                    <span
                        style="font-size:20px;color:#587ddc"
                        v-if="task.status == 8"
                    >已完成</span>
                    <span
                        style="font-size:20px;color:#587ddc"
                        v-if="task.status == 9"
                    >调解中</span>
                    <span
                        style="font-size:20px;color:#587ddc"
                        v-if="task.status == 10"
                    >已结束</span>
                </div>
            </div>

            <div
                class="right"
                v-if="task.employer"
            >
                <div class="title">雇主信息</div>
                <div class="info">
                    <div class="avatar">
                        <div :style="'background: url('+ util.reImg(task.employer.avatar) +') no-repeat center;background-size:100% auto; '"></div>
                    </div>
                    <div class="employer-info">
                        <span
                            class="user-name"
                            :title="task.employer.name"
                        >{{task.employer.name}}</span>
                        <span class="atte">{{task.employer?task.employer.attestation_type_value:''}}</span>
                    </div>
                    <span class="area">{{task.employer?task.employer.area_value:''}}</span>
                </div>
                <div class="user-item">
                    <span class="name">注册时间：</span>
                    <span>{{task.employer?task.employer.ctime:''}}</span>
                </div>
                <div class="user-item">
                    <span
                        class="name"
                        style="float:left"
                    >信用评分：</span>
                    <el-rate
                        style="float:left;padding-top:5px;"
                        :value="parseFloat(task.employer.credence)"
                        disabled
                        show-score
                        text-color="#f59b15"
                    ></el-rate>
                </div>
                <div class="user-item">
                    <span class="name">发布项目：</span>
                    <span class="font">{{task.employer?task.employer.task_total:0}}</span>
                    <span class="dw">个</span>
                </div>

                <!-- <div class="user-item btn">
                    <el-button>本月报名剩余次数：{{userInfo.info.apply_numbers}} 次</el-button>
                </div>-->
            </div>
        </div>

        <!-- ===============================报名信息填写内容=================================  -->
        <div class="t-content">
            <el-form
                ref="signForm"
                :model="signForm"
                :rules="rules"
            >
                <div class="item">
                    <div class="title">您的报价</div>
                    <el-form-item
                        class="body"
                        prop="price"
                        name="price"
                    >
                        <span class="star">*</span>
                        <el-input
                            type="number"
                            v-model="signForm.price"
                            placeholder="请填写您的报价，只有雇主可以看到"
                            :disabled="price_disabled"
                            @blur="local_save"
                        ></el-input>
                        <span style="position:absolute;right:40px;top:0;">元</span>
                    </el-form-item>
                </div>

                <div
                    class="item"
                    v-if="task.task_type==3"
                >
                    <div class="title">预计工期</div>
                    <el-form-item
                        class="body"
                        prop="work_time"
                        name="date"
                    >
                        <span class="star">*</span>
                        <el-input
                            type="number"
                            v-model="signForm.work_time"
                            placeholder="请填写您的预报工时，只有雇主可以看到"
                            :disabled="work_time_disabled"
                            @blur="local_save"
                        ></el-input>
                        <span style="position:absolute;right:40px;top:0;">天</span>
                    </el-form-item>
                </div>

                <div class="item">
                    <div class="title">联系人</div>
                    <el-form-item
                        class="body"
                        prop="name"
                        name="name"
                    >
                        <span class="star">*</span>
                        <el-input
                            type="text"
                            v-model="signForm.name"
                            placeholder="请填写联系人姓名，联系人信息会加密保护，不会对外公开"
                            @blur="local_save"
                        ></el-input>
                    </el-form-item>
                </div>

                <div class="item">
                    <div class="title">联系人电话</div>
                    <el-form-item
                        class="body"
                        prop="mobile"
                        name="mobile"
                    >
                        <span class="star">*</span>
                        <el-input
                            type="text"
                            v-model="signForm.mobile"
                            placeholder="请填写联系人电话，联系人信息会加密保护，不会对外公开"
                            @blur="local_save"
                        ></el-input>
                    </el-form-item>
                    <div style="color:#999;">您在这里填写的联系人和联系方式仅供询龙网工作人员方便联系您使用，并不会显示在发送给雇主的报名信息中，询龙网将严格保护您的隐私信息。平台的所有提示短信仍将发送到您的注册手机号。</div>
                </div>

                <div class="item">
                    <div class="title">报名留言</div>
                    <el-form-item
                        class="body"
                        prop="content"
                        name="content"
                    >
                        <span class="star"></span>
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 4, maxRows: 6}"
                            placeholder="请输入您的报名留言信息，最多100字"
                            v-model="signForm.content"
                            maxlength="500"
                            @blur="local_save"
                        ></el-input>
                    </el-form-item>
                </div>

                <div class="item annex">
                    <div class="title">上传附件</div>
                    <div
                        v-if="task.demand_annex == 1"
                        style="margin-top:5px;color:#b9c0c7;"
                    ><span>附件要求说明：</span><span v-html="task.demand_explain"></span></div>
                    <div :class="annexClass">
                        <upload-annex
                            v-model="signForm.annex"
                            @success="local_save"
                            @del="local_save"
                        ></upload-annex>
                        <div class="tips">您可以上传容量小于15MB，格式为jpg、png的图片文件，格式为doc,docx,pdf的文本文件，或格式为rar, zip的压缩文件。</div>
                    </div>
                </div>

                <div class="item">
                    <div class="agree">
                        <el-checkbox
                            v-model="agree1"
                            :class="empty_warn1"
                            @change="changeCheckbox"
                        >勾选则代表您的资质、 资格证书真实有效 ，并且符合报名要求</el-checkbox>
                    </div>
                    <div class="agree">
                        <el-checkbox
                            v-model="agree2"
                            :class="empty_warn2"
                            @change="changeCheckbox"
                        >
                            我已阅读并同意
                            <span
                                class="agree2"
                                @click="goArticleDetail(2)"
                            >《询龙网平台服务协议》，</span>已阅读并承诺遵守
                            <span
                                class="agree2"
                                @click="goArticleDetail(3)"
                            >《询龙网平台交易规则》</span>
                        </el-checkbox>
                    </div>
                </div>

                <div class="item">
                    <div class="btn">
                        <el-button
                            class="btn1"
                            v-if="userInfo.info.apply_numbers>0"
                            @click="signUp('T01')"
                        >立即报名</el-button>
                        <el-button
                            class="btn2"
                            v-if="userInfo.info.apply_numbers<=0"
                            @click="signUpPay"
                        >立即报名</el-button>
                        <el-button
                            class="btn3"
                            @click="backToTaskDetail(task.tk)"
                        >返回项目</el-button>
                    </div>
                </div>
            </el-form>
        </div>

        <div class="task_sign_empty"></div>

        <global-footer></global-footer>

        <!-- ===============================协议条款弹出框=================================  -->
        <el-dialog
            :title="articleTitle"
            :visible.sync="dialogAgreement"
        >
            <div v-html="articleContent"></div>
        </el-dialog>

        <!-- ===============================支付方式选择弹出框=================================  -->
        <el-dialog
            class="blue-top-border"
            title="点券报名"
            :visible.sync="payTypeSelectDialog"
            width="500px"
            center
        >
            <div class="pointCoupon-buy">
                <template v-if="parseFloat(userInfo.info.points_surplus) >= parseFloat(userInfo.config.enroll_paypoint)">
                    <p>
                        当前点券余额：
                        <span>{{userInfo.info.points_surplus}}点券</span>
                    </p>
                    <p>
                        本次支付消耗：
                        <span>{{userInfo.config.enroll_paypoint}}点券</span>
                    </p>
                    <span
                        class="recharge"
                        @click="goWallet"
                    >点券不够？去充值</span>
                    <el-button
                        type="primary"
                        @click="enroll('D01')"
                    >立即支付</el-button>
                </template>
                <div class="other-buy">
                    <h5>其他报名方式</h5>
                    <ol class="buy-way">
                        <li
                            @click="enroll('Y01')"
                            v-if="parseFloat(userInfo.info.surplus) >= parseFloat(userInfo.config.enroll_paymoney)"
                        >
                            <span class="buy-icon">
                                <i class="jk-icon-yuezhifu"></i>
                            </span>
                            <strong>余额支付</strong>
                        </li>
                        <li @click="enroll('W01')">
                            <span class="buy-icon">
                                <i class="jk-icon-weixinzhifu"></i>
                            </span>
                            <strong>微信支付</strong>
                        </li>
                        <li @click="enroll('A01')">
                            <span class="buy-icon">
                                <i class="jk-icon-zhifubaozhifu"></i>
                            </span>
                            <strong>支付宝支付</strong>
                        </li>
                    </ol>
                </div>
            </div>
        </el-dialog>

        <!-- ===============================微信弹出框=================================  -->
        <el-dialog
            class="blue-top-border"
            title="微信支付"
            :visible.sync="wxBuyDialog"
            width="500px"
            center
            append-to-body
        >
            <div class="pointCoupon-buy">
                <p>支付金额</p>
                <p class="price">
                    ￥
                    <strong>{{userInfo.config.enroll_paymoney}}</strong>（本次）
                </p>
                <div class="buy-ewm">
                    <img :src="pay_image" />
                </div>
                <div class="guide">
                    请使用微信扫描
                    <br />二维码完成支付
                </div>
            </div>
        </el-dialog>

        <!-- ===============================支付宝弹出框=================================  -->
        <el-dialog
            class="blue-top-border"
            title="支付宝支付"
            :visible.sync="zfbBuyDialog"
            width="500px"
            center
            append-to-body
        >
            <div class="pointCoupon-buy">
                <p>支付金额</p>
                <p class="price">
                    ￥
                    <strong>{{userInfo.config.enroll_paymoney}}</strong>（本次）
                </p>
                <div class="buy-ewm">
                    <img :src="pay_image" />
                </div>
                <div class="guide">
                    请使用支付宝扫描
                    <br />二维码完成支付
                </div>
            </div>
        </el-dialog>

        <!-- ===============================余额支付弹出框=================================  -->
        <el-dialog
            class="blue-top-border subs-dialog subys-dialog"
            :title="pay_type == 'D01'?'点券支付':'余额支付'"
            :visible.sync="yeBuyDialog"
            width="400px"
            center
            append-to-body
        >
            <div class="notprov-box">
                <ul class="hosting-list hostings-list">
                    <li class="hosting-item apl-item">
                        <span>{{pay_type == 'D01'?'点券支付':'余额支付'}}</span>
                        <i
                            class="warn"
                            v-if="pay_type == 'D01'"
                        >{{userInfo.config.enroll_paypoint}}</i>
                        <i
                            style="margin:0;padding:0;"
                            class="warn"
                            v-if="pay_type == 'Y01'"
                        >{{userInfo.config.enroll_paymoney}}</i>
                        <span>{{pay_type == 'D01'?'个':'元'}}</span>
                        <span
                            style="margin:0;padding:0;"
                            v-if="pay_type == 'Y01'"
                        >(余额:{{userInfo.info.surplus}}元)</span>
                    </li>
                    <li class="hosting-item">
                        <span>支付密码</span>
                    </li>
                    <li class="hosting-item">
                        <el-input
                            v-model="pay_password"
                            type="password"
                            placeholder="请输入支付密码完成支付"
                        ></el-input>
                    </li>
                </ul>
            </div>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    @click="pay"
                >确认支付</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
import UploadAnnex from "@/components/upload/UploadAnnex.vue";
import Counter from "@/components/common/Counter.vue";

export default {
    name: "taskEnroll",
    components: {
        TopNav, // 头部吊顶
        HeaderGuide, //头部指南
        BreadCrumb, //面包屑导航
        GlobalFooter, //公共底部
        UploadAnnex, //附件上传
        Counter //倒计时
    },
    data() {
        let _this = this;
        return {
            task: [], //项目列表
            empty_warn1: "", //条例未勾选提示
            empty_warn2: "", //条例未勾选提示
            dialogAgreement: false, //协议是否显示
            articleContent: "", //加载《协议》数据内容
            articleTitle: "", //加载《协议》数据标题
            signForm: {
                //报名信息form数据
                price: "",
                date: "",
                name: "",
                mobile: "",
                content: "",
                annex: [] //附件列表
            },
            agree1: false, //是否同意协议checkbox选中值
            agree2: false, //是否同意协议checkbox选中值
            annexClass: "body", //附件样式
            rules: {
                //报名表单验证规则
                price: [
                    {
                        required: true,
                        message: "报价不能为空",
                        trigger: ["change"]
                    },
                    {
                        validator: function (key, value, callback) {
                            if (value) {
                                if (parseInt(value) > parseInt(_this.task.remuneration_value[1])) {
                                    //价格不能比项目最小价格低
                                    callback(
                                        new Error("报价金额不能高于" + _this.task.remuneration_value[1]),
                                        _this.signForm.price = _this.task.remuneration_value[1]
                                    );
                                }
                            }
                            callback();
                        },
                        trigger: ["change"]
                    }
                ],
                date: [
                    {
                        required: true,
                        message: "预报工时不能为空",
                        trigger: ["change"]
                    }
                ],
                name: [
                    {
                        required: true,
                        message: "联系人姓名不能为空",
                        trigger: ["change"]
                    }
                ],
                mobile: [
                    {
                        required: true,
                        message: "联系人电话不能为空",
                        trigger: ["change"]
                    },
                    {
                        validator: this.validata.mobile(),
                        trigger: ["change"]
                    }
                ],
                // content: [
                //     {
                //         required: true,
                //         message: "报名留言信息不能为空",
                //         trigger: ["change"]
                //     },
                //     {
                //         validator: function (key, value, callback) {
                //             if (value) {
                //                 let val = value.trim();
                //                 if (val.length > 100) {
                //                     callback(
                //                         new Error("报名留言信息不能超过100个字")
                //                     );
                //                 }
                //             }
                //             callback();
                //         },
                //         trigger: ["change"]
                //     }
                // ]
            },
            pay_type: 0, //支付方式
            order_no: "", //订单号
            trade_sn: "", //项目交易号
            pay_image: "", //支付二维码图片
            payTypeSelectDialog: false, //支付方式选择对话框
            zfbBuyDialog: false, //支付宝支付弹出框
            wxBuyDialog: false, //微信支付弹出对话框
            yeBuyDialog: false, //余额支付弹出框
            pay_password: "", //支付密码
            timer: "", //支付二维码轮询time
            loading: false, //加载
            price_disabled: false,
            work_time_disabled: false
        };
    },

    computed: {
        ...mapState(["userInfo"]),
        balance: function () {
            return this.userInfo.info.surplus;
        }
    },

    created() {
        this.loadtaskData(); //加载项目数据
        let enroll_form_data = sessionStorage.getItem("enroll_form_data");
        if (enroll_form_data) {
            this.signForm = JSON.parse(enroll_form_data);
        }
    },

    methods: {
        //监听页面滚动
        winScroll(scrollTop) {
            if (scrollTop > 37) {
                this.isFixedTopClass = "aslide-box  aslide-box-fixed";
                this.progressType = "line";
            } else {
                this.progressType = "circle";
                this.isFixedTopClass = "aslide-box";
            }
        },

        //获取项目信息
        loadtaskData() {
            let _this = this;
            _this.post(
                "/task/index/detail",
                {
                    tk: _this.$route.query.tk
                },
                function (res) {
                    if (res.code == 200) {
                        _this.task = res.data;

                        //非竞价项目，则不可填写报价
                        if (res.data.task_type != 1) {
                            _this.price_disabled = true;
                            _this.signForm.price = res.data.remuneration;
                        }

                        //日薪
                        if (res.data.task_type == 3) {
                            _this.signForm.work_time = res.data.task_day;
                            _this.work_time_disabled = true;
                        } else {
                            _this.rules.work_time.required = false;
                        }
                    } else {
                        _this.$message.error("项目不存在");
                        window.history.go(-1);
                    }
                }
            );
        },

        //“报名次数”报名方法
        signUp(type = "") {
            let _this = this;
            _this.pay_type = type;
            if (parseInt(_this.signForm.price) < parseInt(_this.task.remuneration_value[0])) {
                //价格不能比项目最小价格低
                _this.signForm.price = _this.task.remuneration_value[0];
                _this.$message.error("报价金额不能低于" + _this.task.remuneration_value[0] + '自动更为' + _this.task.remuneration_value[0]);
                return false;

            }
            if (_this.formCheck() == true) {
                //验证表单
                //提交报名数据
                _this.loading = true;
                _this.post(
                    "/task/task/enroll",
                    {
                        tk: _this.$route.query.tk, //项目id
                        contact: _this.signForm.name, //报名姓名
                        phone: _this.signForm.mobile, //报名电话
                        content: _this.signForm.content, //报名留言内容
                        work_time: _this.signForm.work_time, //预估工期
                        price: _this.signForm.price, //报名报价
                        annex: _this.signForm.annex, //附件信息
                        pay_type: _this.pay_type, //支付方式
                        trade_sn: _this.trade_sn //项目交易号
                    },
                    function (res) {
                        _this.loading = false;
                        if (res.code == 200) {
                            _this.order_no = res.data.order_no;
                            _this.trade_sn = res.data.trade_sn;
                            if (_this.pay_type == "T01") {
                                //如果是报名次数报名
                                sessionStorage.removeItem("enroll_form_data"); //清除缓存
                                _this.SuccessTips(); //跳转到报名成功页面
                            }
                            return true;
                        } else {
                            _this.$message.error(res.msg);
                            return false;
                        }
                    }
                );
            }
        },

        //勾选协议
        changeCheckbox() {
            if (this.agree1) {
                this.empty_warn1 = "";
            }

            if (this.agree2) {
                this.empty_warn2 = "";
            }
        },

        //验证表单信息
        formCheck() {
            let res = false;
            this.$refs["signForm"].validate((valid, validInfoObj) => {
                if (valid) {
                    //判断是否传了附件
                    if (this.task.demand_annex == 1) {
                        //项目要求必须有附件
                        if (this.signForm.annex.length <= 0) {
                            this.$message({
                                showClose: true,
                                message: "雇主设置了报名该项目需要上传附件，请查看雇主对项目附件的要求，并按要求上传附件",
                                type: "error",
                                duration: 10000
                            });
                            this.annexClass = "body empty_warn";
                            res = false;
                            return res;
                        }
                    }

                    if (!this.agree1) {
                        this.$message({
                            showClose: true,
                            message:
                                "请勾选立即报名按钮上方的复选框，确认您所提交的资质，资格证书真实有效，且符合报名要求。",
                            type: "error",
                            duration: 10000
                        });
                        this.empty_warn1 = "empty_warn";
                        res = false;
                        return res;
                    }

                    if (!this.agree2) {
                        this.$message({
                            showClose: true,
                            message:
                                "请审慎阅读上方《询龙网平台服务协议》以及《询龙网平台交易规则》，确认您理解其中含义，并勾选上方复选框。",
                            type: "error",
                            duration: 10000
                        });
                        this.empty_warn2 = "empty_warn";
                        res = false;
                        return res;
                    }

                    res = true;
                    return res;
                } else {
                    res = false;
                    return res;
                }
            });
            return res;
        },

        //获取文章详情
        goArticleDetail(e) {
            let _this = this;
            _this.post("/article/index/detail", { id: e }, function (res) {
                if (res.code == 200) {
                    _this.articleContent = res.data.content;
                    if (e == 2) {
                        _this.articleTitle = "询龙网平台服务协议";
                    }
                    if (e == 3) {
                        _this.articleTitle = "询龙网平台交易规则";
                    }
                    _this.dialogAgreement = true;
                }
            });
        },

        //返回项目
        backToTaskDetail(e) {
            this.$router.push("/task/detail?tk=" + e);
        },

        //跳转到报名成功页面
        SuccessTips() {
            this.$router.push(
                "/task/enroll_successtips?tk=" + this.$route.query.tk
            );
        },

        //弹出其他方式报名
        signUpPay() {
            let _this = this;
            if (parseInt(_this.signForm.price) < parseInt(_this.task.remuneration_value[0])) {
                //价格不能比项目最小价格低
                _this.signForm.price = _this.task.remuneration_value[0];
                _this.$message.error("报价金额不能低于" + _this.task.remuneration_value[0] + '自动更为' + _this.task.remuneration_value[0]);
                return false;

            }
            if (_this.formCheck() == true) {
                this.payTypeSelectDialog = true; //弹出支付选择对话框
            }
        },

        //其他支付方式选择
        enroll(type) {
            let _this = this;
            _this.closeDialog(); //关闭所有对话框
            _this.pay_type = type;
            _this.signUp(type);
            setTimeout(() => {
                if (type == "Y01" || type == "D01") {
                    if (_this.userInfo.info.has_pay_password == 0) {
                        this.$confirm(
                            "不能进行此操作，您还没有设置支付密码，是否现在设置？",
                            "温馨提示",
                            {
                                confirmButtonText: "确定",
                                cancelButtonText: "取消"
                            }
                        )
                            .then(() => {
                                //存储当前值
                                sessionStorage.setItem(
                                    "currentPageUrl",
                                    document.location.pathname +
                                    document.location.search
                                );
                                _this.$router.push(
                                    "/user/validpassword?type=1&routerName=userSettingPassword&title=设置支付密码"
                                );
                            })
                            .catch(() => { });
                        return false;
                    }
                    this.yeBuyDialog = true; //打开余额支付对话框
                }

                if (type == "W01" || type == "A01") {
                    _this.pay();
                }
            }, 1000);
        },

        //支付
        pay() {
            var _this = this;
            _this.yeBuyDialog = false; //关闭支付对话框

            if (!_this.order_no) {
                _this.$message.error("未获取到订单号，请重试");
                return false;
            }

            _this.pay_image = "";
            if (
                (_this.pay_type == "D01" || _this.pay_type == "Y01") &&
                _this.pay_password == ""
            ) {
                this.$message.error("支付密码不能为空");
                return false;
            }
            _this.loading = true;
            _this.post(
                "/pay/pay/pay",
                {
                    order_no: _this.order_no,
                    password: _this.pay_password
                },
                res => {
                    _this.loading = false;
                    if (res.code == 200) {
                        if (
                            _this.pay_type == "D01" ||
                            _this.pay_type == "Y01"
                        ) {
                            //点券或余额支付
                            sessionStorage.removeItem("enroll_form_data"); //清除缓存
                            _this.SuccessTips();
                        } else {
                            _this.pay_image = this.websiteConfigs.sourceUrl + res.data.img;
                            if (_this.pay_type == "W01") {
                                _this.wxBuyDialog = true; //打开微信支付对话框
                            }
                            if (_this.pay_type == "A01") {
                                _this.zfbBuyDialog = true; //打开支付宝支付对话框
                            }
                            _this.timer = setInterval(() => {
                                _this.isPayCheck();
                            }, 2000);
                        }
                    } else {
                        this.$message.error(res.msg);
                        return false;
                    }
                }
            );
        },

        //是否支付成功
        isPayCheck() {
            let _this = this;
            this.post(
                "/pay/pay/is_pay",
                {
                    order_no: _this.order_no
                },
                function (res) {
                    if (res.code == 200) {
                        sessionStorage.removeItem("enroll_form_data"); //清除缓存
                        _this.$message.success("恭喜你，支付成功！");
                        _this.SuccessTips();
                        clearInterval(_this.timer);
                    }
                    if (
                        _this.wxBuyDialog == false &&
                        _this.zfbBuyDialog == false
                    ) {
                        clearInterval(_this.timer);
                    }
                }
            );
        },

        //关闭所有对话框方法
        closeDialog() {
            this.yeBuyDialog = false;
            this.zfbBuyDialog = false;
            this.zfbBuyDialog = false;
            this.payTypeSelectDialog = false;
        },

        //跳转到钱包充值点券页面
        goWallet() {
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            ); //记录当前的url，方便后面跳转
            this.$router.push("/user/wallet/list");
        },

        //报名时间截止事件
        endCount(e) { },

        //添加表单缓存
        local_save() {
            sessionStorage.setItem(
                "enroll_form_data",
                JSON.stringify(this.signForm)
            );
            if (this.signForm.annex && this.signForm.annex.length > 0) {
                this.annexClass = "body";
            }
        }
    }
};
</script>

<style lang="less">
@import "../../styles/task_employer_info.less";
@import "../../styles/task_sign.less";
.t-top .left .status {
    right: 30px;
    top: 30px;
}
.el-dialog__wrapper {
    .el-dialog {
        .el-dialog__header {
            text-align: center;
        }
    }
}
</style>



